<template>
    <div class="leaderboard-section">
        <h1 class="my-4">Leaderboard</h1>
        <template v-if="isLoading">
            <div class="text-center">
                <div
                    class="spinner-border"
                    style="width: 3rem; height: 3rem"
                    role="status"
                >
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </template>
        <template v-else>
            <template v-if="getMyPointPlace <= 5">
                <div
                    class="d-flex align-items-center mb-4"
                    v-for="(user, index) in data"
                    :key="index"
                >
                    <template v-if="index < 5">
                        <div class="flex-shrink-0">
                            <span class="me-4">{{ index + 1 }}</span>
                            <img
                                width="50"
                                height="50"
                                class="rounded-circle"
                                v-if="user.avatar"
                                :src="user.avatar"
                                alt="user image"
                                :class="{ activeUser: authUser.id == user.id }"
                            />
                            <img
                                v-else
                                class="rounded-circle"
                                src="@/assets/img/empty_user.png"
                                alt="user image"
                                width="50"
                            />
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <div class="leader-board-user-name">
                                {{ user.name }}
                            </div>
                            <div class="leader-board-user-point">
                                <template v-if="type == 'shwe'">{{
                                    user.product_point
                                }}</template>
                                <template v-else-if="type == 'ngwe'">{{
                                    user.point
                                }}</template>
                                <template v-else>{{
                                    user.referral_point
                                }}</template>
                            </div>
                        </div>
                        <div class="flex-end">
                            <!-- {{ changePhoneFormat(user.phone) }} -->
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <div
                    class="d-flex align-items-center"
                    v-for="(user, index) in data"
                    :key="index"
                    :class="[index == data.length - 1 ? 'mb-1' : 'mb-3']"
                >
                    <template v-if="index < 5">
                        <div class="flex-shrink-0">
                            <span class="me-4">{{ index + 1 }}</span>
                            <img
                                width="50"
                                height="50"
                                class="rounded-circle"
                                v-if="user.avatar"
                                :src="user.avatar"
                                alt="user image"
                            />
                            <img
                                v-else
                                class="rounded-circle"
                                src="@/assets/img/empty_user.png"
                                alt="user image"
                                width="50"
                            />
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <div class="leader-board-user-name">
                                {{ user.name }}
                            </div>
                            <div class="leader-board-user-point">
                                <template v-if="type == 'shwe'">{{
                                    user.product_point
                                }}</template>
                                <template v-else-if="type == 'ngwe'">{{
                                    user.point
                                }}</template>
                                <template v-else>{{
                                    user.referral_point
                                }}</template>
                            </div>
                        </div>
                        <div class="flex-end">
                            <!-- {{ changePhoneFormat(user.phone) }} -->
                        </div>
                    </template>
                </div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div style="height: 5px;">.</div>
                <div class="d-flex align-items-center mt-4">
                    <div class="flex-shrink-0">
                        <span class="me-3">{{ getMyPointPlace }}</span>
                        <img
                            width="50"
                            height="50"
                            class="rounded-circle activeUser"
                            v-if="authUser.avatar"
                            :src="authUser.avatar"
                            alt="user image"
                        />
                        <img
                            v-else
                            class="rounded-circle activeUser"
                            src="@/assets/img/empty_user.png"
                            alt="user image"
                            width="50"
                        />
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <div class="leader-board-user-name">
                            {{ authUser.name }}
                        </div>
                        <div class="leader-board-user-point">
                            <template v-if="type == 'shwe'">{{
                                authUser.product_point
                            }}</template>
                            <template v-else-if="type == 'ngwe'">{{
                                authUser.point
                            }}</template>
                            <template v-else>{{
                                authUser.referral_point
                            }}</template>
                        </div>
                    </div>
                    <div class="flex-end">
                        <!-- {{ changePhoneFormat(authUser.phone) }} -->
                    </div>
                </div>
            </template>
            <div class="view-all text-center mt-3 fw-bold">
                VIEW ALL
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ms-1"
                />
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        data: {
            require: true,
        },
        type: {
            require: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters({
            authUser: 'auth/getUser',
            getMyPointPlace: 'point/getMyPointPlace',
        }),
    },

    methods: {
        changePhoneFormat(phone) {
            if (phone) {
                if (phone.charAt(0) == '+') {
                    const firstChars = phone.slice(0, 4);
                    const lastTwoChars = phone.slice(-2);

                    return firstChars + '*****' + lastTwoChars;
                } else {
                    const firstChars = phone.slice(0, 2);
                    const lastTwoChars = phone.slice(-2);

                    return firstChars + '*****' + lastTwoChars;
                }
            }
        },
    },
};
</script>

<style lang="scss">
.activeUser {
    border: 4px solid #ebe409bf;
    padding: 1px;
}
.view-all {
    cursor: pointer;
    font-size: 17px;
}
</style>
