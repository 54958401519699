<template>
    <section class="profileSidebar px-3 px-lg-4 py-4">
        <nav style="--bs-breadcrumb-divider: '\203A'" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#" class="text-decoration-none">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Reward
                </li>
            </ol>
        </nav>

        <div class="welcome-msg my-4 my-sm-5">
            <h3 class="fw-normal">Reward</h3>
        </div>

        <ul class="list-unstyled profileSidebar__nav">
            <li
                @click="$router.push({ name: 'ShwePyae' })"
                class="profileSidebar__item"
                :class="[routeName == 'ShwePyae' ? 'active' : '']"
            >
                Royalty Reward
            </li>
            <li
                @click="$router.push({ name: 'NgwePyae' })"
                class="profileSidebar__item"
                :class="[routeName == 'NgwePyae' ? 'active' : '']"
            >
                Mining Reward
            </li>
            <li
                @click="$router.push({ name: 'ReferralPage' })"
                class="profileSidebar__item"
                :class="[routeName == 'ReferralPage' ? 'active' : '']"
            >
                Referral Reward
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    computed: {
        routeName() {
            return this.$route.name;
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/components/profileSidebar.scss';
</style>
