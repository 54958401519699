<template>
    <div class="point-section container border-bottom pb-4">
        <div class="row">
            <div class="col-md-6">
                <div class="d-flex justify-content-start">
                    <img
                        :src="img"
                        alt="ngwe pyae"
                        width="65"
                        class="img-fluid me-3"
                    />
                    <div class="point-desc">
                        <div class="point mt-2">
                            <b>{{ points }}</b> Points
                            <font-awesome-icon
                                :icon="['fas', 'chevron-right']"
                            />
                        </div>
                        <div class="reward-name">
                            {{ type }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-flex justify-content-end mt-2">
                    <div class="p-4">
                        <font-awesome-icon
                            :icon="['fas', 'redo']"
                            class="point-manage-icons"
                            @click="showRewardRedeemHistory"
                        />
                    </div>
                    <div class="p-4">
                        <font-awesome-icon
                            :icon="['fas', 'info-circle']"
                            class="point-manage-icons"
                        />
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div
                v-if="isRedeemHistory"
                class="modal rewardHistoryModal d-block"
                id="rewardHistoryModal"
                tabindex="-1"
                aria-labelledby="rewardHistoryModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog">
                    <div class="modal-content history">
                        <div class="modal-body">
                            <h1>Reward History</h1>
                            <template v-if="rewards && rewards.length > 0">
                                <div
                                    v-for="(reward, index) in rewards"
                                    class="d-flex align-items-center mb-3"
                                    :key="index"
                                >
                                    <div class="flex-grow-1 ms-3">
                                        <div class="reward-history-header">
                                            <b>{{ reward.status }}</b>
                                            <span class="ms-4">{{
                                                changeDateFormat(
                                                    reward.created_at,
                                                )
                                            }}</span>
                                        </div>
                                        <p class="reward-history-title">
                                            {{ reward.redeem.name }}
                                        </p>
                                        <div class="reward-history-point-code">
                                            <img
                                                v-if="$route.name == 'ShwePyae'"
                                                width="15"
                                                src="@/assets/img/Royalty.png"
                                                alt="point image"
                                                style="vertical-align: text-bottom;"
                                            />
                                            <img
                                                v-else-if="
                                                    $route.name ==
                                                        'ReferralPage'
                                                "
                                                width="15"
                                                src="@/assets/img/Referal1.png"
                                                alt="point image"
                                                style="vertical-align: text-bottom;"
                                            />
                                            <img
                                                v-else
                                                width="15"
                                                src="@/assets/img/Mining.png"
                                                alt="point image"
                                                style="vertical-align: text-bottom;"
                                            />
                                            <b>{{ reward.redeem.point }}</b>
                                            Points | {{ reward.code }}
                                        </div>
                                    </div>
                                    <div class="flex-shrink-0">
                                        <img
                                            class="history-img"
                                            :src="reward.redeem.image"
                                            alt="user image"
                                            width="100"
                                        />
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="text-center">
                                    No Redeem History Found!
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div
                    class="modal-dialog history-modal-close"
                    @click="isRedeemHistory = false"
                >
                    <div class="modal-content">
                        <div class="modal-body">
                            <font-awesome-icon
                                :icon="['fas', 'times']"
                                class="me-1"
                            />
                            Close
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        points: {
            require: true,
            type: Number,
        },
        type: {
            require: true,
            type: String,
        },
        img: {
            require: true,
            type: String,
        },
        rewards: {
            type: Array,
            require: true,
        },
    },

    data() {
        return {
            isRedeemHistory: false,
        };
    },

    methods: {
        showRewardRedeemHistory() {
            this.isRedeemHistory = true;
        },

        changeDateFormat(date) {
            const dateObj = new Date(date);
            let monthNames = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ];
            let monthIndex = dateObj.getMonth();
            let monthName = monthNames[monthIndex];
            let formatted_date =
                monthName +
                ' ' +
                String(dateObj.getDate()).padStart(2, '0') +
                ', ' +
                dateObj.getFullYear();
            console.log(formatted_date, 'format');
            return formatted_date;
        },
    },
};
</script>

<style lang="scss">
.point-manage-icons {
    font-size: 28px;
    cursor: pointer;
}

.history-img {
    border: 0.5px solid #d9d9d9;
}

.history-modal-close {
    max-width: 120px;
    margin: auto;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}

.rewardHistoryModal {
    transition: opacity 0.15s linear;
    background: #0000006e;

    .history {
        height: 700px;
        overflow-y: scroll;
    }
}
</style>
