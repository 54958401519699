<template>
    <div
        class="product-card mb-4"
        @click="
            openDetailModal(
                reward.id,
                reward.image,
                reward.name,
                reward.point,
                reward.description,
            )
        "
    >
        <div class="img-wrapper">
            <img
                :src="reward.image"
                alt="reward product"
                class="img-fluid reward-image"
            />
        </div>
        <div class="ps-2">
            <p class="mt-1 mb-0">
                {{ reward.name }}
            </p>
            <p class="mt-2">
                <img
                    v-if="$route.name == 'ShwePyae'"
                    width="25"
                    src="@/assets/img/Royalty.png"
                    alt="point image"
                />
                <img
                    v-else-if="$route.name == 'ReferralPage'"
                    width="25"
                    src="@/assets/img/Referal1.png"
                    alt="point image"
                />
                <img
                    v-else
                    width="25"
                    src="@/assets/img/Mining.png"
                    alt="point image"
                />
                {{ reward.point }} Points
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reward: {
            type: Object,
            require: true,
        },
    },

    data() {
        return {
            detailModalObj: {
                id: null,
                img: null,
                title: null,
                points: null,
                desc: null,
            },
        };
    },

    methods: {
        openDetailModal(id, img, title, points, desc) {
            console.log('here');
            const detailModalObj = {
                id,
                img,
                title,
                points,
                desc,
            };
            this.$emit('clickedReward', detailModalObj);
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/components/productCard.scss';
.reward-image {
    border: 0.5px solid #d9d9d9;
}

.rewardModal {
    transition: opacity 0.15s linear;
    background: #0000006e;
    display: block;
}
</style>
