<template>
    <div class="reward-section">
        <h1 class="my-4">Redeem</h1>
        <div class="row">
            <div
                class="col-md-6"
                v-for="(reward, index) in rewards"
                :key="index"
            >
                <reward-product
                    :reward="reward"
                    @clickedReward="showRewardDetail"
                />
            </div>
        </div>
        <transition name="fade">
            <div
                v-if="isOpenDetailModal"
                class="modal rewardModal"
                id="rewardModal"
                tabindex="-1"
                aria-labelledby="rewardModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body px-4">
                            <div class="text-center">
                                <img
                                    :src="detailModalObj.img"
                                    alt="modal image"
                                    class="img-fluid w-100 detail-modal-img"
                                />
                            </div>
                            <p class="detail-modal-title mb-1 mt-2">
                                {{ detailModalObj.title }}
                            </p>
                            <div class="points mb-4">
                                <img
                                    v-if="$route.name == 'ShwePyae'"
                                    width="25"
                                    src="@/assets/img/Royalty.png"
                                    alt="point image"
                                />
                                <img
                                    v-else-if="$route.name == 'ReferralPage'"
                                    width="15"
                                    src="@/assets/img/Referal1.png"
                                    alt="point image"
                                />
                                <img
                                    v-else
                                    width="25"
                                    src="@/assets/img/Mining.png"
                                    alt="point image"
                                />
                                {{ detailModalObj.points }}
                                <span class="fw-lighter">Points</span>
                            </div>
                            <div class="reward-desc-section">
                                <p class="fw-bold title px-3 py-2">
                                    Reward Description
                                </p>
                                <p class="px-3 py-2">
                                    {{ detailModalObj.desc }}
                                </p>
                            </div>
                            <div class="text-center">
                                <div class="you-have mb-1">
                                    You have
                                    <img
                                        v-if="$route.name == 'ShwePyae'"
                                        width="15"
                                        src="@/assets/img/Royalty.png"
                                        alt="point image"
                                    />
                                    <img
                                        v-else-if="
                                            $route.name == 'ReferralPage'
                                        "
                                        width="15"
                                        src="@/assets/img/Referal1.png"
                                        alt="point image"
                                    />
                                    <img
                                        v-else
                                        width="15"
                                        src="@/assets/img/Mining.png"
                                        alt="point image"
                                    />
                                    {{ userPoints }} Points
                                </div>
                                <main-button
                                    v-if="userPoints >= detailModalObj.points"
                                    name="Redeem"
                                    @clicked="makeRedeem(detailModalObj.id)"
                                    class="redeem-btn"
                                    :class="{ redeemedBtn: isRedeem }"
                                    :disabled="isRedeem"
                                />
                                <main-button
                                    v-else
                                    name="Redeem"
                                    class="redeem-btn"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="You don't have enough coin to redeem this package."
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-dialog reward-modal-code" v-if="isRedeem">
                    <div class="modal-content">
                        <div class="modal-body text-center">
                            <template v-if="!isLoading">
                                <div class="valid-feedback d-block">
                                    <font-awesome-icon
                                        :icon="['far', 'check-circle']"
                                        class="me-1"
                                    />
                                    <b>Redeem Successful</b>
                                </div>
                                <p class="my-2">
                                    Discount Code :
                                    <span class="fw-bold">{{ code }}</span>
                                </p>
                                <div class="d-flex justify-content-center mb-3">
                                    <button
                                        class="btn me-3"
                                        @click="copyCode(code)"
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'copy']"
                                            class="me-1"
                                        />
                                        Copy Code
                                    </button>
                                    <button class="btn" @click="makePhoto">
                                        <font-awesome-icon
                                            :icon="['fas', 'file-download']"
                                            class="me-1"
                                        />
                                        Save as Photo
                                    </button>
                                </div>
                            </template>
                            <template v-else>
                                <div class="text-center">
                                    <div
                                        class="spinner-border"
                                        style="width: 3rem; height: 3rem"
                                        role="status"
                                    >
                                        <span class="visually-hidden"
                                            >Loading...</span
                                        >
                                    </div>
                                </div>
                            </template>

                            <div
                                @click="closeDetailModal"
                                style="cursor: pointer;"
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'times']"
                                    class="me-1"
                                />
                                Close
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="modal-dialog reward-modal-close"
                    @click="closeDetailModal"
                    v-else
                >
                    <div class="modal-content">
                        <div class="modal-body">
                            <font-awesome-icon
                                :icon="['fas', 'times']"
                                class="me-1"
                            />
                            Close
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import RewardProduct from './RewardProduct.vue';
import Button from '../reusable/Button';
import { mapGetters } from 'vuex';

import GenerateRedeemCode from '@/graphql/mutations/point/GenerateRedeemCode.gql';

import RewardHistory from '@/mixins/RedeemHistory';

export default {
    mixins: [RewardHistory],

    components: {
        'reward-product': RewardProduct,
        'main-button': Button,
    },

    props: {
        rewards: {
            type: Array,
            require: true,
        },
        userPoints: {
            type: Number,
            require: true,
        },
    },

    data() {
        return {
            detailModalObj: {
                id: null,
                img: null,
                title: null,
                points: null,
                desc: null,
            },
            isOpenDetailModal: false,
            isRedeem: false,
            isLoading: false,
            code: '',
        };
    },

    computed: {
        ...mapGetters({
            authUser: 'auth/getUser',
        }),
    },

    methods: {
        showRewardDetail({ id, img, title, points, desc }) {
            // console.log(data);
            this.detailModalObj = {
                id,
                img,
                title,
                points,
                desc,
            };
            this.isOpenDetailModal = true;
        },

        makeRedeem(id) {
            console.log('here');
            this.isRedeem = true;
            this.isLoading = true;

            this.$apollo
                .mutate({
                    mutation: GenerateRedeemCode,

                    variables: {
                        user_id: this.authUser.id,
                        redeem_id: id,
                    },
                })
                .then(response => {
                    console.log(response.data);
                    const redeemCode = response.data.generateRedeemCode;
                    this.code = redeemCode.code;
                    const userObj = {
                        me: {
                            ...redeemCode.user,
                        },
                    };
                    console.log(userObj);
                    this.$store.commit('auth/ADD_USER', userObj);
                    this.isLoading = false;
                    this.getRedeemHistory();
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        closeDetailModal() {
            this.detailModalObj = {
                id: null,
                img: null,
                title: null,
                points: null,
                desc: null,
            };
            this.isOpenDetailModal = false;
        },

        copyCode(text) {
            navigator.clipboard.writeText(text);
        },

        async makePhoto() {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const video = document.createElement('video');

            try {
                const captureStream = await navigator.mediaDevices.getDisplayMedia();
                video.srcObject = captureStream;
                context.drawImage(video, 0, 0, window.width, window.height);
                const frame = canvas.toDataURL('image/png');
                captureStream.getTracks().forEach(track => track.stop());
                window.location.href = frame;
            } catch (err) {
                console.error('Error: ' + err);
            }
        },
    },
};
</script>

<style lang="scss">
.detail-modal-img {
    max-width: 258px;
    height: 258px;
}
.detail-modal-title {
    font-size: 18px;
}
.reward-desc-section {
    background: #f2f2f2;

    .title {
        background: #ededed;
    }
}
.points {
    font-size: 20px;
    font-weight: bold;
}
.you-have {
    font-size: 13px;
}
.redeem-btn {
    min-width: 250px;
    padding: 0.5em 2.75em;
}
.reward-modal-close {
    max-width: 120px;
    margin: auto;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}

.redeemedBtn {
    background-color: #d9d9d9;
    color: #797979;
}

.reward-modal-code {
    .valid-feedback {
        font-size: 18px;
    }
    span {
        font-size: 20px;
    }
    button {
        padding: 0.53em 1.75em;
    }
}
</style>
